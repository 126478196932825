import React from 'react';
import Layout from '../../components/Layout';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import PageHeading from '../../components/PageHeading/PageHeading';
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import axios from 'axios';
import YouTube from 'react-youtube';
import { useQuery } from 'react-query';
import moment from 'moment';

const Webinars = () => {
  const { isLoading, error, data } = useQuery(
    'getWebinars',
    async () => {
      const { data } = await axios.get(
        'https://www.googleapis.com/youtube/v3/playlistItems?part=id%2C%20contentDetails%2Csnippet&playlistId=PLieCJC88WbxH22k9AY8YuR0AmWhQ3sHZX&key=AIzaSyA97Oqlc3JqnAFB9pV4PT-roR9O4q8iXII&maxResults=50'
      );

      const videoList = data.items
        .map(item => ({
          videoId: item.contentDetails.videoId,
          videoPublishedAt: item.contentDetails.videoPublishedAt,
          videoTitle: item.snippet.title,
        }))
        .sort((video1, video2) => {
          return moment(video1.videoPublishedAt).isBefore(
            moment(video2.videoPublishedAt)
          )
            ? 1
            : -1;
        });

      return videoList;
    },
    { refetchOnWindowFocus: false }
  );

  return (
    <Layout>
      <Helmet
        title="VEYM | Webinars"
        meta={[
          {
            name: 'description',
            content: 'Recorded webinars',
          },
        ]}
      />
      <PageHeading>Webinars</PageHeading>
      <Container>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/news">News</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Webinars</BreadcrumbItem>
        </Breadcrumb>
      </Container>
      <br />
      <br />
      <Container>
        {isLoading && <div>Loading...</div>}

        {error && <div>Unable to load webinars. Please try again later.</div>}

        {data &&
          data.map(video => (
            <div className="my-5" key={video.videoId}>
              <h5>{video.videoTitle}</h5>
              <p>{moment(video.videoPublishedAt).format('MMMM Do, YYYY')}</p>
              <YouTube
                videoId={video.videoId}
                // opts={{ width: '100%' }}
              />
            </div>
          ))}
      </Container>
    </Layout>
  );
};

export default Webinars;
